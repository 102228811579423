enum APILinks {
    PRIVACY_FOOTER = '/text/common-text-service/privacyfooter/1',
    PRIVACYPOLICY = '/text/by-a1-smartbooks/privacypolicy/1',
    OFERTA = '/text/by-a1-smartbooks/oferta/1',
    FOOTER = '/text/by-a1-smartbooks/footer/1',
    FOOTER_UNDER = '/text/by-a1-smartbooks/footer-under/1',
    FIREBASE_TOKEN = '/firebase/token',
}

export default APILinks;
